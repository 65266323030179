<script>
import { mapActions, mapGetters } from 'vuex'

import { services } from '../services'
import { snakeToCamel } from '@/support/utils/stringHelper'
import { HEADER_TAB_LINKS } from '../../../constants'

export default {
  name: 'ListPage',

  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    Tabs: () => import('@/components/general/Tabs')
  },

  data () {
    return {
      pagination: {
        order: {
          active: 'desc',
          title: 'asc'
        },

        limit: 9999,
        page: 1,
        lastPage: this.systemEmailList?.lastPage
      },

      orderedSystemEmailList: []
    }
  },

  computed: {
    ...mapGetters('EmailModule', [ 'getSystemEmailList' ]),

    systemEmailListData () {
      return this.getSystemEmailList?.data
    },

    // hasSystemEmailListData () {
    //   return this.getSystemEmailList?.data.length > 0
    // },

    mayWrite () {
      return this.getContextPermission('notification') === 'write'
    }
  },

  created () {
    this.setup()
    this.init()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback'
    ]),

    ...mapActions('EmailModule', [ 'fetchSystemEmailList' ]),

    setup () {
      this.HEADER_TAB_LINKS = HEADER_TAB_LINKS
    },

    init () {
      this.setFetching(true)

      this.fetchSystemEmailList(this.pagination).then(data => {
        this.orderEmailList(data)
      })
        .finally(() => {
          this.setFetching(false)
        })
    },

    edit (id) {
      this.$router.push({
        name: 'notifications.emails.manage',
        params: {
          id: id
        }
      })
    },

    view (id) {
      this.$router.push({
        name: 'notifications.emails.viewer',
        params: {
          id: id
        }
      })
    },

    toggle (systemEmailId, toggleFlag) {
      this.setFetching(true)

      services.attemptToggleSystemEmail(systemEmailId).then(() => {
        this.fetchSystemEmailList(this.pagination).then(data => {
          this.orderEmailList(data)
        })

        if (!toggleFlag) {
          this.setFeedback({ message: this.$t('notifications.systemEmails:feedback.toggle.activate.success') })
        } else {
          this.setFeedback({ message: this.$t('notifications.systemEmails:feedback.toggle.deactivate.success') })
        }
      }).catch(error => {
        this.setFeedback({ message: this.$t('global.error') })
        console.error(new Error(error))
      }).finally(() => {
        this.setFetching(false)
      })
    },

    getTypeName (type) {
      return snakeToCamel(type)
    },

    orderEmailList (data) {
      // Adds name and description for future processing
      data.map((element) => {
        const name = this.$t(`notifications.systemEmails.list:${this.getTypeName(element.type)}.name`)
        const description = this.$t(`notifications.systemEmails.list:${this.getTypeName(element.type)}.description`)

        element.name = name
        element.description = description

        return element
      })

      // Sorts by name
      data.sort((a, b) => {
        const aName = this.$t(`notifications.systemEmails.list:${this.getTypeName(a.type)}.name`).toLowerCase()
        const bName = this.$t(`notifications.systemEmails.list:${this.getTypeName(b.type)}.name`).toLowerCase()

        if (aName > bName) {
          return 1
        }

        if (aName < bName) {
          return -1
        }

        return 0
      }, this)

      // Sorts by status
      data.sort((a, b) => {
        if (a.active && !b.active) {
          return -1
        }

        if (!a.active && b.active) {
          return 1
        }

        return 0
      }, this)
    }
  }

}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('notifications:header.title')"
      :description="$t('notifications:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="HEADER_TAB_LINKS"
      />
    </ContentHeader>

    <div class="center notifications-content">
      <div
        v-if="systemEmailListData.length > 0"
        class="center solutions-table"
      >
        <Datatable :items="systemEmailListData">
          <template slot="thead">
            <tr>
              <th
                class="th"
                width="300"
              >
                {{ $t('notifications.list:datatable.header.1') }}
              </th>
              <th class="th">
                {{ $t('notifications.list:datatable.header.2') }}
              </th>
              <th
                class="th"
                width="100"
              >
                {{ $t('notifications.list:datatable.header.3') }}
              </th>
              <th
                class="th"
                width="40"
              />
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text">{{ $t('notifications.systemEmails.list:' + getTypeName(props.item.type) + '.name') }}</span>
              </td>

              <td class="td">
                <span class="td-text">{{ $t('notifications.systemEmails.list:' + getTypeName(props.item.type) + '.description') }}</span>
              </td>

              <td class="td text-center">
                <span
                  v-if="!!props.item.active"
                  class="td-tag active-ribbon"
                >{{ $t('global.status:sending') }}</span>
                <span
                  v-else
                  class="td-tag inactive-ribbon"
                >{{ $t('global.status:notSending') }}</span>
              </td>

              <td
                class="td td-actions"
                width="40"
              >
                <Dropdown
                  icon="dots-vertical"
                  right
                >
                  <DropdownLink
                    v-if="mayWrite"
                    :text="$t('global:edit')"
                    @click="edit(props.item.id)"
                  />

                  <DropdownLink
                    :text="$t('global:visualize')"
                    @click="view(props.item.id)"
                  />

                  <DropdownLink
                    v-if="!props.item.active && mayWrite"
                    :text="$t('global:activate')"
                    @click="toggle(props.item.id, props.item.active)"
                  />

                  <DropdownLink
                    v-else-if="mayWrite"
                    :text="$t('global:deactivate')"
                    @click="toggle(props.item.id, props.item.active)"
                  />
                </Dropdown>
              </td>
            </tr>
          </template>
        </Datatable>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="../../../assets/scss/Notification.scss"></style>
